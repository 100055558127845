<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Eshop Discount Codes
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-btn
              class="ml-15"
              dark
              color="green"
              disabled
              @click="selectedView = '2'"
            >
              New Item
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="shippingMethods"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Delete this item?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDelete"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="deleteItem"
                    >
                      DELETE
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteDialog(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div
        v-if="selectedView === '2'"
        key="2"
        class="pl-5 pr-5 bg"
      >
        <v-row class="mt-5">
          <h1 class="mb-6">
            Add Shipping Method
          </h1>
          <v-spacer />
          <v-btn
            class="ml-15"
            dark
            color="orange"
            @click="selectedView = '1'; clearForm();"
          >
            Go back
          </v-btn>
        </v-row>
        <v-form
          ref="form"
        >
          <v-card elevation="3" class="mb-6">
            <v-card-title>Details</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="shippingMethodName"
                    :counter="15"
                    label="Shipping Method Name"
                    outlined
                    required
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="shippingMethodPrice"
                    label="Shipping Method Price (SEK)"
                    type="number"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>Picture</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="shippingMethodPicture"
                    label="Shipping Method Picture"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="3" class="mb-6">
            <v-card-title>Description</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="shippingMethodDescription"
                    :counter="20"
                    label="Shipping Method Description"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Butons ------------------- -->
          <v-btn
            v-if="!editing"
            color="success"
            class="mr-4 mt-4"
          >
            <div v-if="btnLoading">
              <v-progress-circular
                indeterminate
                color="white"
              />
            </div>
            <div v-else>
              Submit
            </div>
          </v-btn>
          <v-btn
            v-else
            color="success"
            class="mr-4 mt-4"
            disabled
          >
            <div v-if="btnLoading">
              <v-progress-circular
                indeterminate
                color="white"
              />
            </div>
            <div v-else>
              Save item
            </div>
          </v-btn>
        </v-form>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

export default {
  name: 'Users',
  components: {

  },
  data () {
    return {
      selectedView: '1',
      deleteItemId: 0,
      dialogDelete: false,
      showProgress: true,
      btnLoading: false,
      pullingData: false,
      editing: false,
      shippingMethodName: '',
      shippingMethodPrice: null,
      shippingMethodPriceId: '',
      shippingMethodDescription: '',
      shippingMethodPicture: '',
      shippingMethods: [],
      pagination: {},
      search: '',
      headers: [
        { text: '#', value: 'id' },
        { text: 'Shipping Method Name', value: 'shippingMethodName' },
        { text: 'Price', value: 'shippingMethodPrice' },
        { text: 'Strapi Price Id', value: 'shippingMethodPriceId' },
        { text: 'Description', value: 'shippingMethodDescription' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [10, 30, 50, 100]
      },
      isMounted: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.pullShippingMethods()
    this.isMounted = true
  },
  methods: {
    pullShippingMethods () {
      this.showProgress = true
      this.$store.dispatch('pullShippingMethods').then(resp => {
        if (resp) {
          this.shippingMethods = resp.data
          this.showProgress = false
        }
        this.showProgress = false
      })
    },
    addShippingMethod () {
      this.btnLoading = true
      this.$store.dispatch('addShippingMethod', {
      }).then(resp => {
        this.btnLoading = false
        this.pullShippingMethods()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    editItem (item) {
      this.editing = true
      this.selectedView = '2'
    },
    saveEditedCoupon () {
      this.btnLoading = true
      this.$store.dispatch('editEshopItem', {
      }).then(resp => {
        this.btnLoading = false
        this.pullShippingMethods()
        this.selectedView = '1'
        this.clearForm()
      })
    },
    closeDelete () {
      this.dialogDelete = false
    },
    deleteDialog (item) {
      this.dialogDelete = true
      this.deleteItemId = item.id
    },
    deleteItem () {
      this.$store.dispatch('deleteEshopItem', { itemId: this.deleteItemId }).then(() => {
        this.selectedView = '1'
        this.clearForm()
        this.pullShippingMethods()
        this.closeDelete()
      })
    },
    clearForm () {
      this.editing = false
    }
  }
}
</script>

<style scoped>
</style>
